.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.navbar {
  background-color: #161616;
  height: 75px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-text {
  color: white;
}
.btn {
  /* background-color:transparent; */
  border: none;
  cursor: pointer;
}
.btn:focus {
  outline: 0px;
  box-shadow: none;
}
.btn:disabled {
  cursor: not-allowed;
}
.btn-fill-orange {
  background-color: #ff5000 !important;
  color: white !important;
  border: none !important;
}
.App-link {
  color: #61dafb;
}
#imports_table {
  margin: auto;
  width: -webkit-fill-available;
  max-width: 1180px;
  border: solid 1px lightgrey;
  margin-bottom: 60px;
}
.table_headers {
  background-color: #f1f4f4 !important;
  color: black;
}
.table-header {
  padding: 10px;
  text-align: center;
}
.table-body {
  padding: 10px;
  text-align: center;
}
.table-body-row {
  border-bottom: solid 1px lightgrey;
}
.modal-content {
  margin-top: 160px;
}
.center {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adiv {
  background: #04cb28;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 18px;
  height: 56px;
}

.chat {
  border: none;
  background: #e2ffe8;
  font-size: 15px;
  border-radius: 25px;
}

.bg-white {
  border: 1px solid #e7e7e9;
  font-size: 15px;
  border-radius: 25px;
}

.myvideo img {
  border-radius: 20px;
}

.dot {
  font-weight: bold;
}

.form-control {
  border-radius: 25px;
  border: 5px solid #f0f0f0;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::placeholder {
  font-size: 12px;
  color: #c4c4c4;
}

.buttonClass {
  background-color: blue !important;
}

input[type='radio'] {
  display: inline !important;
}

.App {
  text-align: center;
}

.centerSpinner {
  text-align: center;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.blur {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 50%;
}

@media screen and (max-width: 500px) {
  .main-heading {
    padding-top: 45px !important;
    padding-bottom: 15px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn {
  /* background-color:transparent; */
  border: none;
  cursor: pointer;
}
.btn:focus {
  outline: 0px;
  box-shadow: none;
}
.btn:disabled {
  cursor: not-allowed;
}
.btn-fill-orange {
  background-color: #ff5000;
  color: white;
  border: none;
}
.orange-btn {
  background-color: #ff5000 !important;
  color: #fff !important;
  padding: 10px 20px;
  cursor: pointer;
  height: 40px;
  font-size: 15px;
  margin-right: 0px !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.black-btn {
  background-color: black !important;
  color: #fff !important;
  padding: 10px 20px;
  cursor: pointer;
  height: 40px;
  font-size: 15px;
  margin-right: 0px !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.gray-btn {
  background-color: gray;
  color: #fff !important;
  padding: 10px 20px;
  cursor: pointer;
  height: 40px;
  font-size: 15px;
  margin-right: 0px !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.centered {
  height: 100px; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-btn {
  background-color: red;
  color: #fff !important;
  padding: 10px 20px;
  cursor: pointer;
  height: 40px;
  font-size: 15px;
  margin-right: 0px !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 9.9rem;
  transition: all 0.2s ease-in-out;
}

.element {
  display: inline-block;
  width: 500px;
  height: 100px;
  background: #ce8888;
}

.orange-btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #292a2e;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff5000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow:
    rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow:
    rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
html {
  margin-top: 0px !important;
}
.btn-fill-orange {
  width: 200px;
  height: 50px;
}
.box-two-importer {
  margin: 13px;
  padding: 12px;
  width: 130px;
  height: 130px;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(41, 42, 46, 0.24);
  background-color: #ffffff;
  padding-top: 20px;
  cursor: pointer;
}
.active-box {
  border: solid #ff5000 1px;
  background: rgba(255, 80, 0, 0.06);
}
.info {
  display: none;
}
.title {
  font-size: 28px;
  font-weight: 600;
  color: #292a2e;
}
.table-header {
  padding: 10px;
  text-align: center;
}
.table-body {
  padding: 10px;
  text-align: center;
}
/* vvvvvvvv progress bar css vvvvvvvv */
.progressbar {
  counter-reset: step;
}

.progressbar li {
  position: relative;
  list-style: none;
  float: left;
  width: 25%;
  text-align: center;
}

/* Circles */
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  border: 1px solid #2979ff;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  /* Center # in circle */
  line-height: 39px;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: orange;
  top: 20px; /*half of height Parent (li) */
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:before {
  background: #00e676;
  content: '✔';
}

.progressbar li.active + li:after {
  background: #00e676;
}
/* ^^^^^^^^ progress bar css ^^^^^^^^ */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  /* transform: translateY(27%); */
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff5000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff5000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.field-text {
  position: relative;
  display: inline-block;
}

.field-text-hover {
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  top: 104%;
  left: 55%;
  margin-left: -45px;
  z-index: 1;
}
.field-text-hover::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.field-text:hover .field-text-hover {
  visibility: visible;
}

.txt15 {
  font-size: 15px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.button-align-xero-final-page {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 50px;
  margin-top: 25px;
}

.button-align-xero-ongoing-import {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  margin-top: 25px;
  flex-wrap: wrap;
}

.xero-redio-button-align {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-title {
  display: flex;
}

.integry-apps-header {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 400;
  color: white;
}

#x-integry-container
  .styles-module_button__osBYJ.styles-module_btnSecondary__3rdEx {
  background: #fff;
  border: 1px solid #ff5000;
  color: #ff5000;
}

#x-integry-container
  .styles-module_button__osBYJ.styles-module_btnSecondary__3rdEx:hover {
  background: #ff5000 !important;
  border: 1px solid #ff5000 !important;
  color: white;
}

#x-integry-container
  .styles-module_button__osBYJ.styles-module_btnPrimary__2lzrP {
  border: 1px solid #ff5000;
  background: #ff5000;
  color: white;
}

#x-integry-container #setupContainer {
  margin: 0 auto;
}

#x-integry-container .styles-module_backLink__1Hpzi circle {
  stroke: #ff5000;
}

#x-integry-container .styles-module_backLink__1Hpzi path {
  fill: #ff5000;
}

#x-integry-container .styles-module_authActions__3bjdW {
  display: none;
}

.question-1234 {
  color: #333;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  min-height: 17px;
  min-width: 100px;
}

.description-1234 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  min-height: 17px;
  min-width: 100px;
}

.btn-remove-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}

.gbm-next-install {
  padding: 12px 24px;
  width: 120px;
  margin-top: 24px;
}

.gbm-next-input {
  width: 500px;
  max-width: 100%;
}

.gbm-next-input.input-error {
  border-color: #e22020;
}

@media screen and (max-width: 768px) {
  .gbm-next-input {
    width: 100%;
    max-width: 100%;
  }
}
