.modal_h4 {
  text-align: center;
  color: green;
}

.modal_ok_button {
  background-color: #ff5000;
  border: none;
  font-size: 16px;
  margin-bottom: 15px;
  width: 120px;
}
